export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const SET_ALERT_DIALOG = 'SET_ALERT_DIALOG';
export const CLOSE_ALERT_DIALOG = 'CLOSE_ALERT_DIALOG';
export const SET_USERS = 'SET_USERS';
export const SET_USER = 'SET_USER';
export const CLEAR_USERS = 'CLEAR_USERS';
export const SET_REPOS = 'SET_REPOS';
export const SET_LOADING = 'SET_LOADING';
export const SET_MY_TEAM = 'SET_MY_TEAM';
export const SET_MY_USERS = 'SET_MY_USERS';
export const SET_TEAM_ID = 'SET_TEAM_ID';
export const SET_TEAM_NAME = 'SET_TEAM_NAME';
export const SET_TEAM_DATA = 'SET_TEAM_DATA';
export const SET_REDIRECT_TO = 'SET_REDIRECT_TO';
export const SET_ON_MY_TEAM_PAGE = 'SET_ON_MY_TEAM_PAGE';
export const SET_MY_TEAMS = 'SET_MY_TEAMS';
export const SET_AMAZON_RESPONSE = 'SET_AMAZON_RESPONSE';
export const SET_EMAIL_TO = 'SET_EMAIL_TO';
export const SET_EMAIL_SUBJECT = 'SET_EMAIL_SUBJECT';
export const SET_EMAIL_BCC = 'SET_EMAIL_BCC';
export const SET_EMAIL_BODY = 'SET_EMAIL_BODY';
export const SET_EMAIL_CC = 'SET_EMAIL_CC';